import {getCookie} from "../CookieService/CookieService";

function getAuthorization() {
    return `Basic ${getCookie('token')}`;
}

export function GetAvailableDevices(callback){
    return fetch('/v1/home/getAvailableDevices',
        {
            headers: {
                'Authorization': getAuthorization()
            }
        })
        .then((res) => {
            if(res.status === 200) return res.json();

            throw res;
        })
        .then((res) => {
            res.devices.sort((a, b) => {
                return a.id - b.id;
            });
            return res;
        })
        .then((res) => {
            callback(res)
        })
        .catch((res) => {
            console.error('GetAvailableDevices: ', res);
            window.dispatchEvent(new CustomEvent('showSnackbarStack',{ detail: {
                text: `Non sono riuscito ad ottenere l'elenco dei dispositivi. Controlla la console per errori`,
                variant: 'error'
            }}));
        });
}

export function GetDeviceStatus(devID, callback) {
    return fetch(`/v1/home/getDeviceStatus/${devID}`,
        {
            headers: {
                'Authorization': getAuthorization()
            }
        })
        .then((res) => {
            if(res.status === 200) return res.json();

            throw res;
        })
        .then((res) => {
            callback(res)
        })
        .catch((res) => {
            console.error('GetDeviceStatus: ', res);
            window.dispatchEvent(new CustomEvent('showSnackbarStack',{ detail: {
                    text: `Non sono riuscito ad ottenre lo stato del device. Controlla la console per errori`,
                    variant: 'error'
                }}));
        });
}

export function ToggleDevice(devID, callback){
    return fetch(`/v1/home/toggle/${devID}`,
        {
            method: 'POST',
            headers: {
                'Authorization': getAuthorization()
            }
        })
        .then((res) => {
            if(res.status === 204){
                window.dispatchEvent(new CustomEvent('showSnackbarStack',{ detail: {
                    text: 'Richiesta inviata correttamente',
                    variant: 'success'
                }}));
                return null;
            }

            throw res;
        })
        .catch((res) => {
            console.error('ToggleDevice: ', res);
            window.dispatchEvent(new CustomEvent('showSnackbarStack',{ detail: {
                text: `Errore nell'invio della richiesta`,
                variant: 'error'
            }}));
        });
}
