import React from 'react';

import './Style.css';

function Imports() {
    return (
        <div>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

        </div>
    );
}

export default Imports;
