import React from 'react';

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import './ActionPage.css';

import ResponsiveDrawer from "../../Components/ResponsiveDrawer/ResponsiveDrawer";

import {GetAvailableDevices, GetDeviceStatus, ToggleDevice} from "../../Services/HomeService/HomeService";
import Divider from "@material-ui/core/Divider";

import { setCookie, getCookie } from "../../Services/CookieService/CookieService";

class ActionPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showPopups: true,
      token: '',
      devices: [],
      switchesState: []
    };

    try {
      let str = window.location.hash.split('#')[1];
      console.log("Got token: ", window.atob(str));
      setCookie('token', str, 3*365)
    } catch(e) {
      console.warn("No token given");
    }
  }

  componentDidMount() {
    this.initLocalStorageItem('showPopups', true);
    this.setState({showPopups: JSON.parse(getCookie('showPopups'))});

    this.initLocalStorageItem('token', '')

    this.initDevices();
  }

  initLocalStorageItem(key, defaultVal) {
    let lsData = getCookie(key);
    if(lsData === ""){
      console.log('INIT ', key);
      setCookie(key, JSON.stringify(defaultVal), 2*365);
    }
  }

  getLocalStorageItem(key) {
    return JSON.parse(getCookie(key));
  }

  initDevices(){
    GetAvailableDevices((res) => {
      window.dispatchEvent(new CustomEvent('showSnackbarStack', {detail: {
          text: `L'elenco dei dispositivi sarà aggiornato in ${res.nextRefresh}s`,
          variant: 'info'
        }}))
      this.setState({devices: res.devices});
      for(let dev of res.devices){
        if(dev.type === 'toggleWithStatus'){
          GetDeviceStatus(dev.id, (res) => {
            let swState = this.state.switchesState;
            swState[dev.id] = res.status;
            this.setState({switchesState: swState})
          });
        }
      }
    });
  }

  addAuthenticationToken() {
    let username = prompt('Inserisci Username');
    let password = prompt('Inserisci Password');
    if(!username || !password) return;

    let to_encode = username+":"+password;
    let token = window.btoa(to_encode);
    setCookie('token', token, 2*365);

    this.initDevices();
  }

  togglePopups(){
    let newState = !this.state.showPopups;
    this.setState({showPopups: newState});
    setCookie('showPopups', JSON.stringify(newState), 2*365);
  }

  handleToggleSwitchChange(e, item) {
    let swState = this.state.switchesState;
    swState[item.id] = !this.state.switchesState[item.id];
    this.setState({switchesState: swState})
    ToggleDevice(parseInt(item.id));
  }

  getSwitchState(item) {
    if(this.state.switchesState[item.id] === undefined) return false;
    return this.state.switchesState[item.id];
  }

  render(){
    return (
      <ResponsiveDrawer
        titlebarTitle={
          <div>
            Homedia
          </div>
        }
        menuItems={
          <div>
            <List>
              <ListItem button>
                <ListItemText
                    onClick={() => {
                      this.addAuthenticationToken()
                    }}
                    primary={`Aggiungi credenziali`}
                />
              </ListItem>
              <ListItem button>
                <ListItemText
                    onClick={() => {
                      this.togglePopups()
                    }}
                    primary={`${this.state.showPopups ? 'Disattiva': 'Attiva'} notifiche`}
                />
              </ListItem>
            </List>
          </div>
        }
        content={
          <List>
            {
              this.state.devices.map((item, i) => {
                if(item.type === 'toggleNoStatus'){
                  return (
                      <div
                          key={i}
                      >
                        <ListItem
                            button
                        >
                          <ListItemText
                              onClick={() => {
                                if(this.state.showPopups)  {
                                  if(window.confirm(`Sei sicuro di voler attivare ${item.name}?`)) ToggleDevice(item.id);
                                } else {
                                  ToggleDevice(item.id);
                                }
                              }}
                              primary={item.name}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                  );
                } else if (item.type === 'toggleWithStatus'){
                  return (
                    <div
                          key={i}
                      >
                        <ListItem
                            button
                        >
                          <FormControlLabel
                            control={<Switch checked={this.getSwitchState(item)} onChange={(e) => {
                              this.handleToggleSwitchChange(e, item)
                            }} />}
                            label={item.name}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                  )
                }

                return (<h1>Dispositivo non disponibile</h1>)
              })
            }
          </List>
        }
      />
    );
  }
}

export default ActionPage;
