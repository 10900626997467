import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { useSnackbar } from 'notistack';

import Imports from './Components/Imports/Imports';

import ActionPage from "./Pages/ActionPage/ActionPage";

function App() {
    const { enqueueSnackbar } = useSnackbar();

    window.addEventListener('showSnackbarStack', (e) => {
        enqueueSnackbar(e.detail.text, {
            variant: e.detail.variant
        });
    });

    return (
        <div>
            <Imports/>

            <Router>
                <Switch>
                    <Route path="/">
                        <ActionPage/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
